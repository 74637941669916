import 'mmenu-js/dist/mmenu.css';
import Memnu from 'mmenu-js';

let mobileMenuPosition = 'right';
let mobileMenuTheme = 'light';
if (document.dir == 'rtl') {
  mobileMenuPosition = 'left';
}
const menu = new Memnu('#mobile-menu', {
  'offCanvas': {
    'position': mobileMenuPosition,
    'clone': true
  },
  'setSelected': {
    'hover': true
  },
  'theme': mobileMenuTheme,
  'navbar': {
    'title': ' ',
  },
});
